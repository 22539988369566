import ApiService from '@/services/core/api.service'

const VoucherService = {
  async get (query) {
    return ApiService.get(`user/vouchers?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/vouchers', payload)
  }

}

export default VoucherService
