import ApiService from '@/services/core/api.service'

const VoucherService = {
  async get (query) {
    return ApiService.get(`admin/vouchers?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/vouchers', payload)
  }
}

export default VoucherService
