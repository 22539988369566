import ApiService from '@/services/core/api.service'

const PurposeService = {
  async get (query) {
    return ApiService.get(`admin/purposes?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/purposes', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/purposes/${payload.id}`, payload)
  }
}

export default PurposeService
