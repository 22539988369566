import ApiService from '@/services/core/api.service'

const ReportService = {
  async getVoucherByOrganizations (query) {
    return ApiService.get(`admin/reports/voucher-by-organizations?${query}`)
  },

  async getVoucherByPurposes (query) {
    return ApiService.get(`admin/reports/voucher-by-purposes?${query}`)
  }
}

export default ReportService
