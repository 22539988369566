import ApiService from '@/services/core/api.service'

const WifiVoucherService = {
  async get (query) {
    return ApiService.get(`admin/wifi-vouchers?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/wifi-vouchers', payload)
  }
}

export default WifiVoucherService
