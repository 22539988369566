import ApiService from '@/services/core/api.service'

const SharedListService = {
  async getPurposeList () {
    return ApiService.get('shared/list/purposes')
  },

  async getOrganizationList () {
    return ApiService.get('shared/list/organizations')
  },

  async getDurationList () {
    return ApiService.get('shared/list/durations')
  },

  async getUserList () {
    return ApiService.get('shared/list/users')
  }
}

export default SharedListService
